<template>
  <v-menu
    v-model="showMenu"
    offset-y
    width="370"
    max-width="370"
    bottom
    nudge-left="20"
  >

    <template v-slot:activator="{on, attrs}">
      <v-text-field
        clearable
        rounded
        :placeholder="placeholder"
        class="my-0 ml-2 pr-0 mr-0 search-bar-itinerary"
        :style="style"
        @focus="showMenu = search_ !== '' && items.length > 0"
        clear-icon="fa fa-times"
        background-color="white"
        @input="searchAPI"
        @keydown.enter="$emit('submit')"
        v-model="search_">
        <template v-slot:append-outer>
          <v-btn icon small @click="setMyPosition">
            <v-icon color="primary">far fa-location</v-icon>
          </v-btn>
        </template>
        </v-text-field>
    </template>

    <v-card>
      <v-list v-if="!isLoading && items.length > 0">
        <template v-for="item of items">
          <v-list-item @click="onSelect(item)">
            <v-list-item-icon
              v-if="item.nlp"
              color="primary">
              <v-icon>far fa-microphone</v-icon>
            </v-list-item-icon>
            <v-list-item-icon
              v-else
              color="primary">
              <v-icon>far fa-location</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.label"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item disabled dense>
          <v-row align="start" class="d-block">
            <v-img  position="right" max-height="15" contain src="@/assets/pictures/powered_by_google_on_white.png" />
          </v-row>
        </v-list-item>
      </v-list>

      <Skeleton
        v-else-if="isLoading"
        :occurrence="3"
        type="list-item" />

      <v-list v-else>
        <v-list-item>Pas de résultat</v-list-item>
      </v-list>
    </v-card>

  </v-menu>
</template>

<script>
import {v4 as uuidv4} from "uuid";

export default {
  name: "SearchBarItineraryCustom",

  props: {
    placeholder: {type: String, default: ""},
    noDataText: {type: String, default: ""},
    value: {type: Object, default: () => null}
  },

  components: {
    Skeleton: () => import("@/components/Common/Skeleton")

  },
  data() {
    return {
      showMenu: false,
      search: null,
      search_: null,
      model: null,
      items: [],
      isLoading: false,
      _timerID: null,
      session_token: uuidv4()
    }
  },

  created() {
    if (this.value && this.value.label) {
      this.search_ = this.value.label;
      //this.items = [this.value];
    }
  },

  methods: {
    clearSearch() {
      this.search_ = "";
      this.$emit("reset");
      this.items = [];
      this.showMenu = false;
    },

    onSearch() {

    },

    async onSelect(val) {
      console.log(this.search_);
      console.log(val);
      if (val) {
        this.search_ = val.label;

        const place = await this.$http.get(`/map/search/place-details?place_id=${val.place_id}&session_token=${this.session_token}&coords=${this.$store.getters['coords']}`);
        if (place && place.data) {
          this.isLoading = false;
          this.$emit("selected", {label: val.label, coords: place.data.coords});
        }
      }
    },

    searchAPI(val) {

      this.search_ = val;
      this.isLoading = true;

      if (!!val) {

        this._timerID = setTimeout(() => {
          if (val === this.search_) {
            this.isLoading = true;
            this.$http(`/map/search/autocomplete?q=${val}&session_token=${this.session_token}&coords=${this.$store.getters['coords']}`)
              .then((res) => {
                this.updateItems(res.data.predictions);
              })
              .catch(err => {
                console.log(err);
              })
              .finally(() => {
                this.isLoading = false;
              })
          }
          else {
            this.isLoading = false;
          }
        }, 500);
      }
      else {
        this.items = [];
        this.isLoading = false;
      }
    },

    updateItems(newItems) {
      console.log("UPDATE");

      this.items = [...newItems.map((el) => {
        return {
          label: el.label,
          place_id: el.place_id,
          types: el.types
        }
      })];
      this.showMenu = true;
    },


    setMyPosition() {

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const currentPos = {label: "Ma position", coords: {lng: position.coords.longitude, lat: position.coords.latitude}};
          this.$emit("selected", currentPos);
        });
      }
      else
        this.$store.commit("toast/showError", this.$t('view.geolocation.error'));
    },

    generateSessionToken() {
      this.session_token = uuidv4();
    }
  },


  computed: {
    isMobile() {
      return this.$store.getters['isMobile'];
    },

    style() {
      if (this.isMobile) {
        return 'height: 48px !important; width: 100vw !important; padding-top: 10px !important;';
      }
      else
        return 'height: 48px !important; width: 360px !important; padding-top: 10px !important;';
    },
  },

  watch: {
    value: function (newValue, oldValue) {
      if (newValue.label !== this.search_) {
        this.items = [];
        this.search_ = newValue.label;
      }
    },
    search_(newValue, oldValue) {
    }
  }
}
</script>

<style>
.v-text-field--rounded > .v-input__control > .v-input__slot  {
  padding: 0px !important;
}
.search-bar-itinerary .v-input__slot::before, .search-bar-itinerary > .v-input__control > .v-input__slot:after {
  border-style: none !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot  {
  padding: 0px !important;
}

</style>